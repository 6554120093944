<template>
  <v-row no-gutters class="mt-8 ml-4 mr-4">
    <v-col sm="12" md="1" lg="1" xl="1" cols="12"></v-col>
    <v-col sm="12" md="10" lg="10" xl="10" cols="12">
      <v-card class="flexcard mx-auto">
        <v-card-title class="white--text blue lighten-1 font-weight-bold mb-10">
          <span class="text-xs-subtitle-2 text-sm-subtitel-1 text-md-h5 text-lg-h4"> Loan Application Form </span>
          <v-spacer></v-spacer>
          <v-btn right text outlined @click="BackToLoans" dark>
            <v-icon class="mr-3"> mdi-keyboard-backspace </v-icon>
            <strong> Back </strong>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-5 mb-5">
          <v-row class="ml-6 mr-6 mb-8">
            <v-col cols="12">
              <div class="text-xs-subtitle-2 text-sm-subtitle-1 text-md-h5 text-lg-h5 font-weight-bold"> Loan Details
              </div>
              <div class="text-xs-subtitle-2 text-sm-subtitle-1 text-md-h6 text-lg-h6 infoasterisk"> All fields marked
                with an asterisk (*) are required to be filled in.</div>
              <div v-if="errorbox" class="mt-5">
                <v-alert text prominent type="error">
                  {{ errormessage }}
                </v-alert>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <div class="text-md-body-1 font-weight-bold mb-2">Select Loan Type:<span class="asterisk">*</span></div>
              <v-select item-text="loan_desc" item-value="loan_type" :items="loantype" label="Select Loan Type"
                v-model="selectedloantype" @change="CheckValidLoan" dense solo></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <div class="text-md-body-1 font-weight-bold mb-2">Loan Purpose:</div>
              <v-text-field outlined label="Loan Purpose" aria-placeholder="Loan Purpose" v-model="loanpurpose" dense
                readonly solo>
              </v-text-field>
            </v-col>
            <v-col v-if="pettycash" cols="12">
              <v-simple-table class="text-right">
                <tbody>
                  <tr>
                    <td><strong class="text-h6 font-weight-bold">PRINCIPAL</strong></td>
                    <td>
                      <v-layout justify-end>
                        <v-checkbox class="font-weight-bold" v-model="loanamount" label="Php 5,000.00" value="5000">
                        </v-checkbox>
                      </v-layout>
                    </td>
                    <td>
                      <v-layout justify-end>
                        <v-checkbox class="font-weight-bold" v-model="loanamount" label="Php 8,000.00" value="8000">
                        </v-checkbox>
                      </v-layout>
                    </td>
                    <td>
                      <v-layout justify-end>
                        <v-checkbox class="font-weight-bold" v-model="loanamount" label="Php 10,000.00" value="10000">
                        </v-checkbox>
                      </v-layout>
                    </td>
                  </tr>
                  <tr class="text-right font-weight-bold">
                    <td>Interest</td>
                    <td>12%</td>
                    <td>14%</td>
                    <td>14%</td>
                  </tr>
                  <tr class="text-right font-weight-bold">
                    <td>Term-Month</td>
                    <td>10</td>
                    <td>12</td>
                    <td>12</td>
                  </tr>
                  <tr class="text-right font-weight-bold">
                    <td>Total Payable</td>
                    <td>5,600.00</td>
                    <td>9,120.00</td>
                    <td>11,400.00</td>
                  </tr>
                  <tr class="text-right font-weight-bold">
                    <td>Semi-Monthly Deduction</td>
                    <td>Php 280.00</td>
                    <td>Php 380.00</td>
                    <td>Php 475.00</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col v-if="appmisc" cols="12">
              <v-row>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <div class="text-md-body-1 font-weight-bold mb-2">Loan Amount:<span class="asterisk">*</span></div>
                  <v-text-field outlined label="Loan Amount" v-model="loanamount" dense solo type="number"
                    :rules="[rules.required, rules.minAmount, rules.maxAmount]">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <div class="text-md-body-1 font-weight-bold mb-2">Item(s):<span class="asterisk">*</span></div>
                  <v-text-field outlined label="Items" v-model="loanitems" dense solo type="text">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                  <div class="text-md-body-1 font-weight-bold mb-2">Term:<span class="asterisk">*</span></div>
                  <v-select :items="miscloanterm" label="Select Loan Term" v-model="selectedloanterm" dense solo>
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="loanPO" cols="12">
              <v-row>
                <v-col cols="12" sm="8" md="8" lg="8" xl="8">>
                  <div class="text-md-body-1 font-weight-bold mb-2">Loan Amount:<span class="asterisk">*</span></div>
                  <v-text-field outlined label="Loan Amount" v-model="loanamount" dense solo type="number">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                  <div class="text-md-body-1 font-weight-bold mb-2">Term:<span class="asterisk">*</span></div>
                  <v-select :items="loanterm" label="Select Loan Term" v-model="selectedloanterm" readonly dense solo>
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="specialLoan" cols="12">
              <v-row>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <div class="text-md-body-1 font-weight-bold mb-2">Loan Amount:<span class="asterisk">*</span></div>
                  <v-text-field outlined label="Loan Amount" v-model="loanamount" dense solo type="number" readonly>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <div class="text-md-body-1 font-weight-bold mb-2">Ocassion:<span class="asterisk">*</span></div>
                  <v-text-field outlined label="Items" v-model="loanitems" dense solo type="text">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                  <div class="text-md-body-1 font-weight-bold mb-2">Term:<span class="asterisk">*</span></div>
                  <v-text-field readonly label="Loan Term" v-model="selectedloanterm" dense solo></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="8">
            </v-col>
            <v-col cols="4">
              <v-layout justify-end>
                <v-btn class="white--text blue lighten-1 font-weight-bold" elevation="2" block x-large
                  :loading="loading" @click="loanApply">Apply</v-btn>
              </v-layout>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col sm="12" md="1" lg="1" xl="1" cols="12"></v-col>
    <div class="text-center">
      <v-dialog v-model="loading" persistent width="600">
        <v-card color="blue lighten-1" v-if="afterloading">
          <v-card-text color="blue lighten-1">
            <div class="text-center white--text mb-10 pt-5">
              <h3>Saving...</h3>
            </div>
            <div class="text-center mb-5">
              <v-progress-circular class="text-center" :size="80" :width="8" color="white" indeterminate>
              </v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
        <v-card color="green accent-3" v-else>
          <v-card-text>
            <div class="text-center white--text pt-5">
              <h2>Thank you for applying have a nice day!</h2>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-row>
</template>
<script>
import LoanService from '@/services/LoanService'
export default {
  data () {
    return {
      loading: false,
      memberno: this.$route.params.memberno,
      loantype: [],
      selectedloantype: null,
      loanpurpose: '',
      loanamount: null,
      selectedloanterm: null,
      selectedpaymentmode: null,
      loanterm: [1, 2, 3, 5, 6, 10, 12, 24, 36],
      miscloanterm: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      pettycash: false,
      appmisc: false,
      loanPO: false,
      specialLoan: false,
      errorbox: false,
      afterloading: true,
      errormessage: '',
      loanitems: '',
      loantermpayment: [
        {term: '1', Description: 'Daily'},
        {term: '7', Description: 'Weekly'},
        {term: '15', Description: 'Semi-Monthly'},
        {term: '30', Description: 'Monthly'},
        {term: '4', Description: 'Quarterly'},
        {term: '12', Description: 'Yearly'}
      ],
      rules: {
        required: value => !!value || 'Required.',
        minAmount: v => (v && v >= 500) || 'Minimum of 500 loan amount',
        maxAmount: v => (v && v <= 50000) || 'Maximum of 50,000 loan amount'
      }
    }
  },
  mounted () {
    this.getLoanTypes()
  },
  methods: {
    BackToLoans () {
      this.$router.push({ path: `/loanapplication/${this.memberno}` })
    },
    async getLoanTypes () {
      const result = await LoanService.GetLoanTypes()
      if (result.status === 200) {
        if (result) {
          this.loantype = result.data.result
        }
      }
    },
    async Getfixdep () {
      const fixdep = await LoanService.GetFixDeposit(this.memberno)
      if (fixdep.status === 200) {
        if (fixdep) {
          return fixdep.data.result[0].balance
        } else {
          return null
        }
      }
    },
    async GetTotalLoanBalance () {
      const loanbalance = await LoanService.GetMemberLoanBalance(this.memberno)
      if (loanbalance.status === 200) {
        if (loanbalance) {
          return loanbalance.data.balance
        } else {
          return null
        }
      }
    },
    async GetMemberShipAge () {
      const membershipage = await LoanService.GetMembershipAge(this.memberno)
      if (membershipage.status === 200) {
        if (membershipage) {
          return membershipage.data.membershipage
        } else {
          return null
        }
      }
    },
    async GetLoanPurpose (loantype) {
      const purpose = await LoanService.GetLoanPurpose(loantype)
      if (purpose.status === 200) {
        if (purpose) {
          return purpose.data.loanpurpose
        } else {
          return null
        }
      }
    },
    async GetLoanTerm (loantype) {
      const term = await LoanService.GetLoanPurpose(loantype)
      if (term.status === 200) {
        if (term) {
          return term.data.term
        } else {
          return null
        }
      }
    },
    async CheckValidLoan () {
      this.loanpurpose = await this.GetLoanPurpose(this.selectedloantype)
      this.selectedloanterm = await this.GetLoanTerm(this.selectedloantype)
      switch (this.selectedloantype) {
        case '11711.4':
          // console.log('petty cash')
          this.pettycash = true
          this.appmisc = false
          this.loanPO = false
          this.specialLoan = false
          this.loanamount = null
          this.selectedloanterm = null
          break
        case '11711.2':
          // console.log('appliance')
          this.pettycash = false
          this.appmisc = true
          this.loanPO = false
          this.specialLoan = false
          this.loanamount = null
          break
        case '11711.5':
          // console.log('miscellaneous')
          this.pettycash = false
          this.appmisc = true
          this.loanPO = false
          this.specialLoan = false
          this.loanamount = null
          break
        case '11711.3':
          // console.log('PO')
          this.pettycash = false
          this.appmisc = false
          this.loanPO = true
          this.specialLoan = false
          this.loanamount = null
          break
        case '11711.12':
          // console.log('special loan')
          this.pettycash = false
          this.appmisc = false
          this.loanPO = false
          this.specialLoan = true
          this.loanamount = 4000
          this.loanterm = 10
          break
        default:
          // alert('Please select Loan Type')
          this.pettycash = false
          this.appmisc = false
          this.loanPO = false
          this.specialLoan = false
          this.loanamount = null
          break
      }
    },
    async loanApply () {
      this.loading = true
      if (this.selectedloantype === null) {
        this.loading = false
        this.errorbox = true
        this.errormessage = 'Please select loan type'
      } else {
        if (this.selectedloantype === '11711.4') {
          if (this.loanamount === '5000') {
            this.selectedloanterm = 10
            this.loanitems = 'N/A'
          } else if (this.loanamount === '8000' || this.loanamount === '10000') {
            this.selectedloanterm = 12
            this.loanitems = 'N/A'
          } else {
            this.selectedloanterm = null
          }
        } else if (this.selectedloantype === '11711.12') {
          this.term = 10
        } else if (this.selectedloantype === '11711.5') {
          if (parseFloat(this.loanamount) < 500) {
            this.loading = false
            this.errorbox = true
            this.errormessage = 'Loan Amount must be 500 or more'
            return null
          } else if (parseFloat(this.loanamount > 50000)) {
            this.loading = false
            this.errorbox = true
            this.errormessage = 'Loan Amount must not be more than 50,000'
            return null
          }
        }
        if (this.loanamount === null || this.loanamount <= 0) {
          this.loading = false
          this.errorbox = true
          this.errormessage = 'Please select or enter loan amount!!'
        } else {
          if (this.loanitems === '' || this.loanitems === null) {
            this.loading = false
            this.errormessage = true
            this.errormessage = 'Please Enter Occasion/Items'
          } else {
            if (this.selectedloanterm === null) {
              this.loading = false
              this.errorbox = true
              this.errormessage = 'Please select loan term'
            } else {
              const response = await LoanService.SaveLoanApplication({
                memberno: this.memberno,
                loan_type: this.selectedloantype,
                loanamount: this.loanamount,
                purpose: this.loanpurpose,
                term: this.selectedloanterm,
                loanitems: this.loanitems
              })
              if (response.status === 200) {
                this.afterloading = false
                setTimeout(() => {
                  this.$router.push({name: 'loanapplication', params: { memberno: this.memberno }})
                }, 3000)
              } else {
                this.loading = false
                console.log(response.data)
              }
            }
          }
        }
      }
    }
  }
}
</script>
<style scoped>
.infoasterisk{
  color: #7cb3ed
}
.asterisk{
  color: red;
  font-size: 1.2em;
}
.principal-cap{
  margin:auto
}
</style>

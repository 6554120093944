<template>
<div>
    <v-btn
    color="red accent-2"
    dark
    @click="Backbutton"
    >
    Back
    </v-btn>
    <iframe :src="pdfpath" width="100%" height="1000px"></iframe>
</div>
</template>
<script>
import pdf from 'vue-pdf'
import memberService from '@/services/MembersService'
export default {
  data () {
    return {
      memberno: this.$route.params.memberno,
      pdfpath: ''
    }
  },
  components: {
    pdf
  },
  mounted () {
    this.GetDividend()
  },
  methods: {
    Backbutton () {
      this.$router.push({name: 'home'})
    },
    async GetDividend () {
      try {
        const response = await memberService.ViewDividend({
          memberno: this.memberno
        })
        if (response.status === 200) {
          this.pdfpath = response.data.pdf
        }
      } catch (error) {
        console.log(error.respnse.data.error)
      }
    }
  }
}
</script>
<style scoped>
</style>

<template>
  <div>
    <!-- <v-dialog v-model="dialog" persistent max-width="600px" min-width="360px" content-class="my-custom-dialog"> -->
    <v-row>
      <v-col cols="12" xs="12" xm="12" md="3" xl="4" lg="4"></v-col>
      <v-col cols="12" xs="12" xm="12" md="6" xl="4" lg="4">
        <v-card max-width="600px" class="justify-center text-center mt-6" elevation="5">
          <v-card-text>
            <v-tabs v-model='tab' show-arrows background-color='blue lighten-1' icons-and-text dark grow>
              <v-tabs-slider color='light-blue darken-4'></v-tabs-slider>
              <v-tab v-for='i in tabs' :key='i.name'>
                <v-icon large>{{ i.icon }}</v-icon>
                <div class='caption py-1'>{{ i.name }}</div>
              </v-tab>
              <v-tab-item>
                <v-card class='px-4'>
                  <v-card-text>
                    <v-form ref='loginForm' v-model='valid' lazy-validation>
                      <form @keyup.enter="validateLogin">
                        <v-row>
                          <v-col cols='12' v-if="iserror">
                            <v-alert border="left" color="blue lighten-1" elevation="1" type="error">{{ errorMessage }}
                              <div v-if="isconfirmation">
                                Please click <a @click="reSendConfirmation"><b>here</b></a> re-send email confirmation
                              </div>
                            </v-alert>
                          </v-col>
                          
                          <v-col cols='12'>
                            <h1>Member Login </h1>
                          </v-col>
                          <v-col cols="12">
                            <v-select 
                              item-text="text" 
                              item-value="value" 
                              v-model="selectedBranch"
                              :items="branch" 
                              label="Select Branch" 
                              :rules="[rules.required]" 
                              required>
                            </v-select>
                          </v-col>
                          <v-col cols='12'>
                            <v-text-field v-model='loginmemberno' label='Member No'
                              :rules="[rules.required, rules.minMember]" required></v-text-field>
                          </v-col>
                          <v-col cols='12'>
                            <v-text-field v-model='loginpassword' :rules="[rules.required]"
                              :append-icon="show1?'eye':'eye-off'" :type="show1 ? 'text' : 'password'" name='input-10-1'
                              label='Password' hint='At least 8 characters' counter @click:append='show1 = !show1'>
                            </v-text-field>
                          </v-col>
                          <v-col class='d-flex' cols='12' sm='6'>
                            <v-btn text @click='ForgetPassword' color='primary' class='justify-center'>Foget Password?
                            </v-btn>
                          </v-col>
                          <v-spacer></v-spacer>
                          <v-col class='d-flex' cols='12' sm='3' xsm='12'>
                            <v-btn x-large block :disabled='!valid' color='blue lighten-1' @click='validateLogin'
                              class="white--text"> Login </v-btn>
                          </v-col>
                        </v-row>
                      </form>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card class='px-4'>
                  <v-card-text>
                    <v-form ref='registerForm' v-model='valid' lazy-validation v-if="!isRegistered">
                      <v-row>
                        <v-col cols="12">
                          <v-select item-text="text" item-value="value" :items="branch" v-model="selectedBranch" label="Select Branch" :rules="[rules.required]" required>
                          </v-select>
                        </v-col>
                        <v-col cols='12'>
                          <v-text-field v-model='memberno'
                            :rules="[rules.required, rules.minMember, checkmemberno, checkMemberExist]"
                            label='Member No' maxlength='20' required></v-text-field>
                        </v-col>
                        <v-col cols='12' sm='6' md='6'>
                          <v-text-field v-model='firstName' :rules="[rules.required]" label='First Name' maxlength='20'
                            required></v-text-field>
                        </v-col>
                        <v-col cols='12' sm='6' md='6'>
                          <v-text-field v-model='lastName' :rules="[rules.required]" label='Last Name' maxlength='20'
                            required></v-text-field>
                        </v-col>
                        <v-col cols='12'>
                          <v-text-field v-model="date" label="Birthday date" type="date"
                            :rules="[rules.required, checkBdate]"></v-text-field>
                          <!-- <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                        </template>
                                        <v-date-picker
                                          ref="picker"
                                          v-model="date"
                                          :max="new Date().toISOString().substr(0, 10)"
                                          min="1950-01-01"
                                          @change="save"
                                        ></v-date-picker>
                                      </v-menu> -->
                        </v-col>
                        <v-col cols='12'>
                          <v-text-field
                            v-model='email' label='E-mail'
                            :rules="[rules.required, rules.email, checkEmail]" required></v-text-field>
                        </v-col>
                        <v-col cols='12'>
                          <v-text-field v-model='password' :rules="[rules.required, rules.min]"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'"
                            name='input-10-1' label='Password' hint='At least 8 characters' counter
                            @click:append='show = !show'></v-text-field>
                        </v-col>
                        <v-col cols='12'>
                          <v-text-field block v-model='verify' :rules="[rules.required, passwordMatch]"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'"
                            name='input-10-1' label='Confirm Password' counter @click:append='show1 = !show1'>
                          </v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col class='d-flex ml-auto' cols='12' sm='12'>
                          <v-btn x-large block :disabled='!valid' :loading="loading" color='blue lighten-1'
                            @click='validateRegister' class="white--text">Register</v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                    <div v-if="isRegistered">
                      <v-col cols='12' v-if="isSuccess">
                        <v-alert dense elevation="6" prominent type="success">Please check your email for confirmation!
                        </v-alert>
                      </v-col>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" xm="12" md="3" xl="4" lg="4"></v-col>
    </v-row>
    <!-- <v-row class="mt-10">
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="justify-center text-center">
        <LazyYoutube
          :src="video1"
          max-width="720px"
          aspect-ratio="16:9"
          thumbnail-quality="standard"
        />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="justify-center text-center">
        <LazyYoutube
          :src="video2"
          max-width="720px"
          aspect-ratio="16:9"
          thumbnail-quality="standard"
        />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="justify-center text-center">
        <LazyYoutube
          :src="video3"
          max-width="720px"
          aspect-ratio="16:9"
          thumbnail-quality="standard"
        />
      </v-col>
    </v-row> -->
    <div class="text-center">
      <v-dialog v-model="loading" persistent width="300">
        <v-card color="blue lighten-1">
          <v-card-text color="blue lighten-1">
            <div class="text-center white--text mb-10 pt-5">
              <h3>Saving...</h3>
            </div>
            <div class="text-center mb-5">
              <v-progress-circular class="text-center" :size="50" :width="5" color="white" indeterminate>
              </v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <!-- </v-dialog> -->
  </div>
</template>

<script>
import AuthService from '@/services/AuthenticationService'
import { LazyYoutube } from 'vue-lazytube'
import { EventBus } from '@/event-bus.js'

export default {
  components: {
    LazyYoutube
  },
  data: () => ({
    video1: '',
    video2: '',
    video3: '',
    branch: [],
    selectedBranch: '',
    vidTutorial: true,
    isconfirmation: false,
    dialog: true,
    iserror: false,
    isSuccess: false,
    loading: false,
    tab: 0,
    tabs: [
      {name: 'Login', icon: 'mdi-account'},
      {name: 'Register', icon: 'mdi-account-outline'}
    ],
    date: null,
    menu: false,
    isRegistered: false,
    valid: true,
    firstName: '',
    lastName: '',
    memberno: '',
    email: '',
    password: '',
    verify: '',
    loginpassword: '',
    loginmemberno: '',
    errorMessage: '',
    successMessage: '',
    loginEmailRules: [
      v => !!v || 'Required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    emailRules: [
      v => !!v || 'Required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    show1: false,
    show: false,
    rules: {
      required: value => !!value || 'Required.',
      min: v => (v && v.length >= 8) || 'Min 8 characters',
      minMember: v => (v && v.length >= 4) || 'Min 4 characters',
      email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    }
  }),
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  computed: {
    passwordMatch () {
      if (this.password === this.verify) {
        return true
      } else {
        return 'Password must match'
      }
    }
  },
  mounted () {
    this.setBranches()
  },
  methods: {
    updateBranch () {
      EventBus.$emit('update-branch','')
    },
    async setBranches () {
      try {
        const result = await AuthService.getBranches()
        if (result.status === 200) {
          this.branch = result.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    ForgetPassword () {
      this.$router.push({name: 'forgetpassword'})
    },
    async validateRegister () {
      if (this.$refs.registerForm.validate()) {
        this.loading = true
        try {
          const response = await AuthService.register({
            member_no: this.memberno,
            member_name: this.lastName + ', ' + this.firstName,
            email: this.email,
            password: this.password,
            branch: this.selectedBranch,
          })
          if (response.status === 200) {
            this.loading = false
            this.successMessage = response.data.message
            this.isSuccess = true
            this.isRegistered = true
          } else {
            console.log(response.status)
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
    async reSendConfirmation() {
      try{
        const response = await AuthService.ReSendConfirmation({
          memberno: this.loginmemberno,
          branch: this.selectedBranch
        })
        if (response.status === 200) {
          this.errorMessage = 'We send you a confirmation email please check to activate account!'
          this.isconfirmation = false
        } else {
          this.errorMessage = 'Wrong information please contact Bugasong MPC office'
        }
      } catch(error) {
        console.log(error)
      }
    },
    async validateLogin () {
      try {
        const response = await AuthService.login({
          member_no: this.loginmemberno,
          password: this.loginpassword,
          branch: this.selectedBranch,
        })
        if (response.status === 200) {
          if (response.data.user.verified === 0) {
            this.errorMessage = 'Please confirm email to activate account!!'
            this.iserror = true
            this.isconfirmation = true
          } else {
            this.$store.dispatch('setToken', response.data.token)
            this.$store.dispatch('setUser', response.data.user)
            this.$store.dispatch('setBranch', this.selectedBranch)
            this.updateBranch()
            this.$router.push({name: 'home'})
          }
        } else {
          this.errorMessage = response.data.error
          this.iserror = true
        }
      } catch (error) {
        this.errorMessage = error.response.data.error
        this.iserror = true
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    save (date) {
      this.$refs.menu.save(date)
    }
  },
  asyncComputed: {
    async checkmemberno () {
      if (this.memberno.length > 0) {
        const chk = await AuthService.checkMemberNo({ memberno: this.memberno, branch: this.selectedBranch })
        if (chk) {
          if (chk.data.value === 0) {
            return 'Member no does not exist'
          } else {
            return true
          }
        } else {
          return true
        }
      } else {
        return true
      }
    },
    async checkBdate () {
      try {
        if (this.memberno.length > 0 && this.date !== null) {
          const bdate = await AuthService.checkMemberBdate({
            memberno: this.memberno, 
            bdate: this.date,
            branch: this.selectedBranch,
          })
          if (bdate) {
            if (bdate.data.value === 0) {
              return 'Member Birth date did not match'
            } else {
              return true
            }
          } else {
            return true
          }
        } else {
          return true
        }
      } catch (error) {
        console.log(error)
      }
    },
    async checkEmail () {
      if (this.memberno.length > 0 && this.email.length > 0) {
        const email = await AuthService.checkMemberEmail({
          memberno: this.memberno,
          branch: this.selectedBranch,
        })
        if (email) {
          if (email.data.email !== this.email) {
            return 'Email Address did not match please contact office!'
          } else {
            return true
          }
        } else {
          return true
        }
      } else {
        return true
      }
    },
    async checkMemberExist () {
      if (this.memberno.length > 0) {
        const member = await AuthService.checkMemberExist({
          memberno: this.memberno,
          branch: this.selectedBranch,
        })
        if (member) {
          if (member.data.value === 1) {
            return 'Member no already exist!!'
          } else {
            return true
          }
        } else {
          return true
        }
      } else {
        return true
      }
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
  >>> .my-custom-dialog {
    margin-top: 130px;
    align-self: flex-start;
  }
  >>> .v-messages__message {
    color: blue
  }
</style>

<template>
  <v-row no-gutters class="mt-8 ml-4 mr-4">
    <v-col class="mb-5">
      <v-btn right large dark left @click="BackToHome" color="blue lighten-1">
        <v-icon class="mr-3"> mdi-keyboard-backspace </v-icon> <strong> Back </strong>
      </v-btn>
    </v-col>
    <v-spacer></v-spacer>
    <v-col class="text-right">
      <v-btn :key="activeFab.icon" :color="activeFab.color" large dark class="text-right" @click="ApplyLoan">
        <strong class="mr-3"> Apply Loan </strong>
        <v-icon> {{ activeFab.icon }} </v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-tabs v-model="tabs" left color="blue lighten-1" next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline" show-arrows>
        <v-tab v-for="stat in status" :key="stat.name" @click="LoadLoan(stat.name)"
          class="text-xs-subtitle-2 text-sm-subtitel-1 text-md-h6 text-lg-h6 text-xl-h6">
          <strong> {{ stat.name }} </strong>
        </v-tab>
      </v-tabs>
    </v-col>
    <v-col cols="12">
      <v-tabs-items v-model="tabs" align-with-title>
        <v-tab-item>
          <v-card class='flexcard' flat>
            <!-- Loan pending -->
            <v-card-text class="text-h4 text-center font-weight-light mt-5 text--disabled" v-if="!pending">
              <div class="mb-3 mt-4">
                <v-icon color="red" x-large>
                  mdi-file-document-outline </v-icon>
              </div>
              <div>
                No pending loan found.
              </div>
            </v-card-text>
            <v-card-text v-if="pending">
              <v-data-table dense :headers="Pendingheaders" :items="loans" item-key="name" class="elevation-1">
                <template v-for="header in headers.filter((header) =>
                              header.hasOwnProperty('formatter')
                          )" v-slot:[`item.${header.value}`]="{ header, value }">
                  {{ header.formatter(value) }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <!-- Approve -->
            <v-card-text class="text-h4 text-center font-weight-light mt-5 text--disabled" v-if="!approved">
              <div class="mb-3 mt-4">
                <v-icon color="red" x-large>
                  mdi-file-document-outline </v-icon>
              </div>
              <div>
                No approved loan found.
              </div>
            </v-card-text>
            <v-card-text v-if="approved">
              <v-data-table dense :headers="headers" :items="loans" item-key="name" class="elevation-1">
                <template v-for="header in headers.filter((header) =>
                              header.hasOwnProperty('formatter')
                          )" v-slot:[`item.${header.value}`]="{ header, value }">
                  {{ header.formatter(value) }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <!-- released -->
            <v-card-text class="text-h4 text-center font-weight-light mt-5 text--disabled" v-if="!released">
              <div class="mb-3 mt-4">
                <v-icon color="red" x-large>
                  mdi-file-document-outline </v-icon>
              </div>
              <div>
                No released loan found.
              </div>
            </v-card-text>
            <v-card-text v-if="released" class="mt-5">
              <v-data-table dense :headers="headers" :items="loans" item-key="name" class="elevation-1">
                <template v-for="header in headers.filter((header) =>
                              header.hasOwnProperty('formatter')
                          )" v-slot:[`item.${header.value}`]="{ header, value }">
                  {{ header.formatter(value) }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <!-- declined -->
            <v-card-text class="text-h4 text-center font-weight-light mt-5 text--disabled" v-if="!declined">
              <div class="mb-3 mt-4">
                <v-icon color="red" x-large>
                  mdi-file-document-outline </v-icon>
              </div>
              <div>
                No declined loan found.
              </div>
            </v-card-text>
            <v-card-text v-if="declined">
              <v-data-table dense :headers="DeclinedHeaders" :items="loans" item-key="name" class="elevation-1">
                <template v-for="header in headers.filter((header) =>
                              header.hasOwnProperty('formatter')
                          )" v-slot:[`item.${header.value}`]="{ header, value }">
                  {{ header.formatter(value) }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <!-- cancelled -->
            <v-card-text class="text-h4 text-center font-weight-light mt-5 text--disabled" v-if="!cancelled">
              <div class="mb-3 mt-4">
                <v-icon color="red" x-large>
                  mdi-file-document-outline </v-icon>
              </div>
              <div>
                No cancelled loan found.
              </div>
            </v-card-text>
            <v-card-text v-if="cancelled">
              <v-data-table dense :headers="headers" :items="loans" item-key="name" class="elevation-1">
                <template v-for="header in headers.filter((header) =>
                              header.hasOwnProperty('formatter')
                          )" v-slot:[`item.${header.value}`]="{ header, value }">
                  {{ header.formatter(value) }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>
<script>
import LoanService from '@/services/LoanService'
import moment from 'moment'
import numeral from 'numeral'
export default {
  data () {
    return {
      memberno: this.$route.params.memberno,
      tabs: null,
      fab: false,
      hidden: false,
      pending: false,
      approved: false,
      released: false,
      declined: false,
      cancelled: false,
      dateFormat: 'MM/DD/YYYY',
      datedesc: '',
      status: [
        { name: 'Pending' },
        { name: 'Approved' },
        { name: 'Released' },
        { name: 'Declined' },
        { name: 'Cancelled' }
      ],
      loans: []
    }
  },
  computed: {
    activeFab () {
      return { color: 'blue lighten-1', icon: 'mdi-file-document-multiple' }
    },
    headers () {
      return [
        {
          text: 'Loan Id',
          align: 'start',
          value: 'loan_id'
        },
        {
          text: 'Application Date',
          value: 'apply_date',
          formatter: (x) => (x ? moment(x).format(this.dateFormat) : null)
        },
        {
          text: 'Loan Type',
          value: 'loan_type'
        },
        {
          text: 'Loan Amount',
          value: 'applied_loan_amt',
          formatter: (x) => (x ? this.formatExample(x) : 0.00)
        },
        {
          text: 'Loan Purpose',
          value: 'loan_purpose'
        },
        {
          text: 'Term',
          value: 'term'
        },
        {
          text: 'Interest Rate',
          value: 'int_rate',
          formatter: (x) => (x ? this.formatInterest(x) : 0.0000000)
        },
        {
          text: 'Approved Amount',
          value: 'apprvd_loan_amt',
          formatter: (x) => (x ? this.formatExample(x) : 0.00)
        },
        {
          text: this.datedesc,
          value: 'appvd_date',
          formatter: (x) => (x ? moment(x).format(this.dateFormat) : null)
        }
      ]
    },
    Pendingheaders () {
      return [
        {
          text: 'Loan Id',
          align: 'start',
          value: 'loan_id'
        },
        {
          text: 'Application Date',
          value: 'apply_date',
          formatter: (x) => (x ? moment(x).format(this.dateFormat) : null)
        },
        {
          text: 'Loan Type',
          value: 'loan_type'
        },
        {
          text: 'Loan Amount',
          value: 'applied_loan_amt',
          formatter: (x) => (x ? this.formatExample(x) : 0.00)
        },
        {
          text: 'Loan Purpose',
          value: 'loan_purpose'
        },
        {
          text: 'Term',
          value: 'term'
        },
        {
          text: 'Interest Rate',
          value: 'int_rate',
          formatter: (x) => (x ? this.formatInterest(x) : 0.0000000)
        }
      ]
    },
    DeclinedHeaders () {
      return [
        {
          text: 'Loan Id',
          align: 'start',
          value: 'loan_id'
        },
        {
          text: 'Application Date',
          value: 'apply_date',
          formatter: (x) => (x ? moment(x).format(this.dateFormat) : null)
        },
        {
          text: 'Loan Type',
          value: 'loan_type'
        },
        {
          text: 'Loan Amount',
          value: 'applied_loan_amt',
          formatter: (x) => (x ? this.formatExample(x) : 0.00)
        },
        {
          text: 'Loan Purpose',
          value: 'loan_purpose'
        },
        {
          text: 'Term',
          value: 'term'
        },
        {
          text: 'Interest Rate',
          value: 'int_rate',
          formatter: (x) => (x ? this.formatInterest(x) : 0.0000000)
        },
        {
          text: 'Reason for disapprove',
          value: 'reason_for_disapprove'
        }
      ]
    }
  },
  mounted () {
    this.LoadLoan('Pending')
  },
  methods: {
    formatExample (value) {
      return numeral(value).format('0,0.00')
    },
    formatInterest (value) {
      return numeral(value).format('0,0.0000000')
    },
    ApplyLoan () {
      this.$router.push({name: 'loanapply', params: { memberno: this.memberno }})
    },
    BackToHome () {
      this.$router.push({name: 'home'})
    },
    async LoadLoan (tabname) {
      let response
      switch (tabname) {
        case 'Pending':
          response = await LoanService.GetLoanPending({
            member_no: this.memberno
          })
          if (response.status === 200) {
            if (response.data.result.length > 0) {
              this.pending = true
              this.loans = response.data.result
            } else {
              this.pending = false
            }
          }
          break
        case 'Approved':
          response = await LoanService.GetLoanApproved({
            member_no: this.memberno
          })
          if (response.status === 200) {
            if (response.data.result.length > 0) {
              this.approved = true
              this.datedesc = 'Approve Date'
              this.loans = response.data.result
            } else {
              this.approved = false
            }
          }
          break
        case 'Released':
          response = await LoanService.GetLoanReleased({
            member_no: this.memberno
          })
          if (response.status === 200) {
            if (response.data.result.length > 0) {
              this.released = true
              this.datedesc = 'Approve Date'
              this.loans = response.data.result
            } else {
              this.released = false
            }
          }
          break
        case 'Declined':
          response = await LoanService.GetLoanDeclined({
            member_no: this.memberno
          })
          if (response.status === 200) {
            if (response.data.result.length > 0) {
              this.declined = true
              this.datedesc = 'Declined Date'
              this.loans = response.data.result
            } else {
              this.declined = false
            }
          }
          break
        case 'Cancelled':
          response = await LoanService.GetLoanCancelled({
            member_no: this.memberno
          })
          if (response.status === 200) {
            if (response.data.result.length > 0) {
              this.cancelled = true
              this.datedesc = 'Cancelled Date'
              this.loans = response.data.result
            } else {
              this.cancelled = false
            }
          }
          break

        default:
          break
      }
    }
  }
}
</script>
<style scoped>
.flexcard {
  display: flex;
  flex-direction: column;
}
.flexcard .v-toolbar {
  flex: 0;
}
 #lateral .v-btn--example {
  position: sticky;
    margin: 16px 32px 16px 16px;
    float: right;
}
</style>

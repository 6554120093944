import Api from '@/services/Api'

export default {
  GetLoanPending (credentials) {
    return Api().post('api/loan/loanapplication/pending', credentials)
  },
  GetLoanApproved (credentials) {
    return Api().post('api/loan/loanapplication/approve', credentials)
  },
  GetLoanReleased (credentials) {
    return Api().post('api/loan/loanapplication/release', credentials)
  },
  GetLoanDeclined (credentials) {
    return Api().post('api/loan/loanapplication/decline', credentials)
  },
  GetLoanCancelled (credentials) {
    return Api().post('api/loan/loanapplication/cancel', credentials)
  },
  GetLoanTypes () {
    return Api().get('api/loan/loantype')
  },
  GetFixDeposit (credentials) {
    return Api().get('api/loan/fixdeposit/' + credentials)
  },
  GetMemberLoanBalance (credentials) {
    return Api().get('api/loan/loantotalbalance/' + credentials)
  },
  GetMembershipAge (credentials) {
    return Api().get('api/member/membershipage/' + credentials)
  },
  GetLoanPurpose (credentials) {
    return Api().get('api/loan/loanpurpose/' + credentials)
  },
  SaveLoanApplication (creentials) {
    return Api().post('api/loan/saveloan', creentials)
  }
}

import Api from '@/services/Api'

export default {
  register (credentials) {
    return Api().post('api/user/register', credentials)
  },
  login (credentials) {
    return Api().post('api/user/login', credentials)
  },
  checkMemberNo (credentials) {
    return Api().post('api/user/checkmemberno', credentials)
  },
  checkMemberBdate (credentials) {
    return Api().post('api/user/checkmemberbdate', credentials)
  },
  checkMemberEmail (credentials) {
    return Api().post('api/user/checkmemberemail', credentials)
  },
  checkMemberExist (credentials) {
    return Api().post('api/user/checkmemberexist', credentials)
  },
  ActiveMemberLogin (credentials) {
    return Api().post('/api/user/activelogin', credentials)
  },
  checkUserEmail (credentials) {
    return Api().post('/api/user/checkuseremail', credentials)
  },
  resetPasswordEmail (credentials) {
    return Api().post('/api/user/resetpassword', credentials)
  },
  resetPassword (credentials) {
    return Api().post('api/user/changepassword', credentials)
  },
  userPassword (credentials) {
    return Api().post('api/user/userpassword', credentials)
  },
  ReSendConfirmation (credentials) {
    return Api().post('api/user/resendconfirmation', credentials)
  },
  getBranches () {
    return Api().get('api/user/getBranchesSelection')
  },
  GetBranchName (credentials) {
    return Api().post('api/user/getBranchName', credentials)
  } 

}

<template>
  <v-container class="pb-10">
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" xl="12" lg="12">
        <v-card outlined>
          <v-card-title class='cardtitle text-caption text-sm-body-1 text-md-title text-lg-h6 text-xl-h5' color='red accent-2'>
            <h4>MEMBER LOAN PROFILE</h4>
            <h4>LOAN ID: {{ loanid }}</h4>     
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon outlined color="white--text" dark class="mr-3" v-on="on" @click="goHome">
                  <v-icon>mdi-arrow-left-circle</v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text class='pt-8'>
            <v-row>
              <v-col sm="12" md="6" xs="6" lg="6" xl="6" cols="12">
                <v-text-field class='text-caption text-sm-body-1 text-md-title text-lg-title text-xl-h6'
                  v-model="member_no" readonly dense outlined label="Member No."></v-text-field>
              </v-col>
              <v-col sm="12" md="6" xs="6" lg="6" xl="6" cols="12">
                <v-text-field class='text-caption text-sm-body-1 text-md-title text-lg-title text-xl-h6'
                  v-model="member_name" readonly dense outlined label="Member Name:"></v-text-field>
              </v-col>
              <v-col sm='12' cols="12" md="12" xl="12" lg="12">
                <v-card-title class="pt-4 pb-4 cardtitle">
                  <v-row>
                    <v-col cols="6" sm="6" md="6" xl="3" lg="3">
                      <span class="text-caption text-sm-body-1 text-md-title text-lg-title text-xl-h6">Loan
                        Type: {{ loan_type }}</span>
                    </v-col>
                    <v-col cols="6" sm="6" md="6" xl="3" lg="3">
                      <span class="text-caption text-sm-body-1 text-md-title text-lg-title text-xl-h6">Loan Amount: {{
                        loanamt }}</span>
                    </v-col>
                    <v-col cols="6" sm="6" md="6" xl="3" lg="3">
                      <span class="text-caption text-sm-body-1 text-md-title text-lg-title text-xl-h6">Loan Date: {{
                        loandate
                        }}</span>
                    </v-col>
                    <v-col cols="6" sm="6" md="6" xl="3" lg="3">
                      <span class="text-caption text-sm-body-1 text-md-title text-lg-title text-xl-h6">Balance: {{
                        balance }}</span>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-col>
              <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12">
                <v-data-table :headers="headers" :items="loandetails" item-key="index" class="elevation-1">
                  <template v-for="header in headers.filter((header) =>
                                    header.hasOwnProperty('formatter')
                                )" v-slot:[`item.${header.value}`]="{ header, value }">
                    {{ header.formatter(value) }}
                  </template>
                </v-data-table>
                <div class="table-footer-prepend d-flex pl-2 align-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn icon outlined color="primary" class="mr-3" v-on="on" @click="goHome">
                        <v-icon>mdi-arrow-left-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Back</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MemberService from '@/services/MembersService'
import moment from 'moment'
import numeral from 'numeral'
export default {
  data () {
    return {
      loanid: '',
      member_no: '',
      member_name: '',
      loandetails: [],
      dateFormat: 'MM/DD/YYYY',
      loan_type: '',
      balance: '',
      loandate: '',
      loanamt: ''
    }
  },
  mounted () {
    this.loanid = this.$route.params.loanid
    this.getMembersProfile()
    this.getMemberLoanProfile()
    // this.getMemberLoanHeader()
  },
  methods: {
    goHome () {
      this.$router.push({ path: `/home` })
    },
    formatExample (value) {
      // Just an simple example, you can do anything here
      return numeral(value).format('0,0.00')
    },
    async getMembersProfile () {
      const member = await MemberService.GetMemberProfile({
        memberno: this.$store.state.user.member_no,
        branch: this.$store.state.branch
      })
      if (member.status === 200) {
        if (member) {
          this.member_no = member.data.member.member_no
          this.member_name = member.data.member.member_name
          await this.getMemberLoanHeader()
        }
      } else {
        console.log(member.status)
      }
    },
    async getMemberLoanProfile () {
      MemberService.GetMemberLoanDetail({ loanid: this.loanid, branch: this.$store.state.branch }).then((res) => {
        this.loandetails = res.data.result
      }).catch((error) => {
        console.log(error.response.data.error)
      })
    },
    async getMemberLoanHeader () {
      const result = await MemberService.GetMemberLoanHeader({ memberno: this.member_no, loanid: this.loanid , branch: this.$store.state.branch})
      if (result.status === 200) {
        console.log(result)
        if (result) {
          this.loan_type = result.data.result.loan_desc
          this.balance = this.formatExample((result.data.result.loan_amt - result.data.result.payments))
          this.loandate = moment(result.data.result.loan_date).format(this.dateFormat)
          this.loanamt = this.formatExample(result.data.result.loan_amt)
        } else {
          console.log('error')
        }
      } else {
        console.log(result.response)
      }
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'tran_date',
          width: '10%',
          formatter: (x) => (x ? moment(x).format(this.dateFormat) : null)
        },
        {
          text: 'Reference',
          sortable: false,
          width: '10%',
          value: 'tran_no'
        },
        {
          text: 'Particulars',
          sortable: false,
          value: 'particulars',
          width: '40%'
        },
        {
          text: 'Principal',
          sortable: false,
          value: 'prin_payment',
          align: 'right',
          width: '10%',
          formatter: (x) => (x ? this.formatExample(x) : '0.00')
        },
        {
          text: 'Interest',
          sortable: false,
          value: 'int_payment',
          align: 'right',
          width: '10%',
          formatter: (x) => (x ? this.formatExample(x) : '0.00')
        },
        {
          text: 'Fines',
          sortable: false,
          value: 'fines_payment',
          align: 'right',
          width: '10%',
          formatter: (x) => (x ? this.formatExample(x) : '0.00')
        },
        {
          text: 'Balance',
          sortable: false,
          value: 'balance',
          align: 'right',
          width: '10%',
          formatter: (x) => (x ? this.formatExample(x) : '0.00')
        }
      ]
    }
  }
}

</script>
<style scoped>
.cardtitle {
    color: white;
    background-color: #42A5F5;
    padding: 30px 0px 30px 40px;

}
.table-footer-prepend {
  margin-top: -58px;
  height: 58px;
}

</style>
